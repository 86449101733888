
  /* headings  */
  .heading1 {
    @apply font-bold text-2xl capitalize
  }
  .heading2{
    @apply font-semibold text-xl capitalize
  }

 /* cards & layouts  */
.card{
  @apply rounded-[10px] sm:rounded-[12px] bg-white backdrop-blur-[50px] shadow-[0_0_20px_rgba(0,0,0,0.05)]
}
.small-card{
  @apply rounded-[12px] bg-white backdrop-blur-[50px] overflow-hidden shadow-[0_0_20px_rgba(0,0,0,0.05)]
}
.transparent-card{
  @apply rounded-[10px] bg-[#FaFaFa]/80 backdrop-blur-md shadow-md
}
.pageLayout{
  @apply mx-auto md:ml-20 mt-2 lg:h-[86vh] w-[90vw] md:w-[85vw] lg:w-[90vw] xl:w-[93vw] flex
}
.moduleCover {
  @apply w-full h-full fixed top-0 left-0 z-30 flex justify-center items-center bg-black/[0.15] backdrop-blur-[4px]
}

  
  /* custom scrollbar */
  .scrollable::-webkit-scrollbar {
    @apply w-2
  }
  .scrollable::-webkit-scrollbar-track {
    @apply bg-transparent
  }
  .scrollable::-webkit-scrollbar-thumb {
    @apply bg-[#ddd] rounded-xl
  }
  
  /* feedback colors */
  .feedback-1{@apply w-fit py-1 px-5 bg-[#FD8080]}
  .feedback-2{@apply w-fit py-1 px-5 bg-[#FF9880]}
  .feedback-3{@apply w-fit py-1 px-5 bg-[#FFB280]}
  .feedback-4{@apply w-fit py-1 px-5 bg-[#FFC980]}
  .feedback-5{@apply w-fit py-1 px-5 bg-[#FEE780]}
  .feedback-6{@apply w-fit py-1 px-5 bg-[#FFFF80]}
  .feedback-7{@apply w-fit py-1 px-5 bg-[#E7FD87]}
  .feedback-8{@apply w-fit py-1 px-5 bg-[#CCFF80]}
  .feedback-9{@apply w-fit py-1 px-5 bg-[#B1FF80]}
  .feedback-10{@apply w-fit py-1 px-5 bg-[#99FF80]}